import moment from "moment";

jQuery(document).ready(function ($) {

  // mobile menu
  $('.menu-trigger').click(function () {
    $(this).toggleClass('active')
    $('.nav').toggleClass('active')
  })

  $('.nav a').click(function () {
    $('.nav, .menu-trigger').removeClass('active')
  })

  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_15528781/events?app_id=45PRESS_ivan_theva',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#tour-dates');
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<div class="event-group">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue-location">';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn btn-green btn-rounded">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
      }
      if (n < 9) {
        $("#toggle-dates").hide();
      }
    }
  });

  $.ajax({
    url: 'https://www.juicer.io/api/feeds/ivan-11940b3f-373c-449d-a5a1-0350ae698ad1',
    method: 'GET',
    dataType: 'json',
    success: data => {
      // console.log(data)
      let html = '';
      let i = 0;
      if (data.posts.items.length > 1) {
        for (let item of data.posts.items) {
          html += '<div>';
          html += '<a href="' + item.full_url + '" target="_blank">';
          html += '<img src="' + item.image + '" alt="' + item.id + '" />';
          html += '</a>';
          html += '</div>';

          i++;
          if (i >= 8) {
            break;
          }
        }
      }
      html += ''

      $('#instagram-posts').html(html);
    },
    error: () => {
      alert('Error fetching Instagram posts!');
    }
  });

});